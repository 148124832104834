@import 'normalize.css';

$black: #01060E;
$blue: #39BAE6;
$grey: #3D424D;
$shadow: 3px 9px 22px rgba(0, 0, 0, 0.52);
$yellow: #E6B450;
$white: #FAFAFA;

* {
  box-sizing: border-box;
}

a {
  border-bottom: .25rem solid transparent;
  color: $white;
  padding: 0.5rem .25rem;
  text-decoration: none;
  transition: .1s linear;

  &:focus {
    outline: 2px solid $blue;
  }

  &:focus,
  &:hover {
    border-bottom: .25rem solid $white;
  }
}

body {
  background: $black;
  color: $white;
  font-family: "Anonymous Pro", monospace;
  font-size: 14px;
  font-size: calc(14px + .25vw);
  margin: 0 auto;
  padding: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.container {
  line-height: 1.69rem;
  margin: 0 auto;
  max-width:  960px;
  padding: 0.5rem;
}

.container__header {
  display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.container__header__title {
  margin-bottom: 0;
  padding-bottom: 0;
}

.container__header__subtitle {
  margin-bottom: 2rem;
}

.container__header__social-media-list {
  padding: 1rem;

  li {
    display: inline-block;
    padding-bottom: 2rem;

    a {
      color: $white;
    }
  }

  svg {
    max-width: 16px;
  }
}

.footer {
  padding: 2rem 0;
  text-align: center;
}

.header {
  background: rgb(201,0,34);
  background: linear-gradient(135deg, rgba(201,0,34,1) 35%, rgba(213,200,0,1) 100%);
  padding: 2rem 1rem;
  width: 100%;
}

.header__figure {
  background: $black;
  border-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top: 25px solid lighten($grey, 25%);
  box-shadow: $shadow;
  font-size: 16px;
  font-size: calc(16px + .25vw);
  margin: 1rem auto;
  max-width: 550px;
  min-height: 450px;
  padding: 2rem 1rem;
  width: 100%;
}

.header__figure__logo {
  display: block;
  height: auto;
  margin: auto;
  max-width: 250px;
  padding: 2rem 0;
}

.info__list--inline {
  display: grid;
  grid-template-columns: 2fr 10fr;

  .info__list__desc {
    margin: 0;
    padding-left: 0.5rem;
  }
}

.info__list__desc {
  margin: 1rem 2rem;
  padding-bottom: 2rem;
}

.info__list__desc__details {
  color: lighten($grey, 40%);
}

.info__list__desc__details__title {
  font-weight: bold;
}

.info__list__desc__list {
  list-style: disc;
}

.info__list__desc__list--inline {
  list-style: none;

  li {
    display: inline-block;

    &:not(:last-of-type)::after {
      content: ",";
    }
  }
}

.info__list__desc__details {
  display: flex;
    flex-wrap: wrap;

  li {
    display: inline-block;
    font-size: 11px;
    font-size: calc(11px + .25vw);
    width: 50%;

    &:last-of-type {
      width: 100%;
    }

    &:not(:first-of-type) {
      font-style: italic;
      text-align: right;
    }
  }
}

.info__list__term {
  font-weight: bold;
}

.info__list__term--inline {
  display: inline-block;
  padding-right: 1rem;
}

@media (min-width: 755px) {
  body {
    font-size: 21px;
    font-size: calc(21px + .25vw);
  }

  .container {
    line-height: 2.15rem;
    padding: 1rem;
  }

  .container__header__social-media-list {
    &:not(:last-of-type)::after {
      content: "|";
      font-size: 21px;
      font-size: calc(21px + .25vw);
    }
  }

  .info__list__desc__details {
    li {
      font-size: 18px;
      font-size: calc(18px + .25vw);
    }
  }

  .info__list--inline {
    .info__list__desc {
      padding-left: 0;
    }
  }
}
